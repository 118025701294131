header.site-header, footer.site-footer {
  display: none;
}

article.post {
  .post-header {
    display: none;
  }

  .post-content {
    ul.task-list {
      column-count: 2;
      column-gap: 1em;

      li {
        page-break-inside: avoid;
      }

      &>li > ul {
        column-count: 1;
        column-gap: 0;
      }
    }

    ul.task-list:not(:last-child) {
      &:not(:last-child) {
        page-break-after: always;
      }

      &:last-child {
        page-break-inside: avoid;
      }
    }
  }

  p {
    page-break-inside: avoid;
  }

  .no-print + * {
    display: none;
  }

  a {
    color: black;
    text-decoration: none;
  }
}

.page-content {
  padding: 0;
}
